<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div class="mt-4">
      <v-card-title v-if="canList">
        Filtro
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisa"
          single-line
          hide-details
          @change="handleSearchImplementos"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headersComputed"
        :search="search"
        :items="implementos"
        sort-by="ds_placa_cavalo"
        class="border"
        :server-items-length="implementos_total"
        :options.sync="options"
        @update:options="handleGetImplementos"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Implementos registrados</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-icon
              v-if="canList"
              medium
              @click="() => handleGetImplementos()"
              title="Atualizar"
              >mdi-refresh</v-icon
            >
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="700px">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-if="canCreate"
                  color="primary"
                  dark
                  class="mb-2"
                  v-on="on"
                  @click="adicionarImplemento"
                  >Novo</v-btn
                >
              </template>

              <v-card>
                <v-card-title>
                  <span class="text-h5">Novo implemento</span>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="dialog = false">
                    Fechar
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <CadastroImplemento
                      @addedImplemento="addedImplemento"
                      @close="close"
                      v-if="dialog"
                    />
                  </v-container>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.manufacturing_date`]="{ item }">
          {{ item.manufacturing_date | date2br }}
        </template>
        <template v-slot:[`item.documentos`]="{ item }">
          {{ item.documentos.map((i) => i.description).join(', ') }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small v-if="canUpdate" @click="editarImplemento(item)"
            >mdi-pencil</v-icon
          >
          <v-icon small v-if="canDelete" @click="prepareDeleteItem(item)"
            >mdi-delete</v-icon
          >
        </template>
        <template #no-data v-if="!canList">
          <span class="red--text"
            >Você não tem permissão para visualizar as informações desta
            tela</span
          >
        </template>
      </v-data-table>
    </div>

    <v-dialog v-if="canDelete" v-model="dialogDeleteItem" max-width="500px">
      <v-card>
        <v-card-title>
          <v-card-text>
            <h3 class="transition-swing text-h3 mb--1 error--text">Cuidado!</h3>
            <p class="mt-8">Tem certeza que quer deletar esse veículo?</p>
          </v-card-text>
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="
              dialogDeleteItem = false
              implementoDelete = {}
            "
          >
            Cancelar
          </v-btn>
          <v-btn color="primary" text @click="deleteItem(implementoDelete)">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="canUpdate" v-model="dialogUpdateItem" max-width="700px">
      <v-card>
        <v-card-title>
          <div class="d-flex flex-column align-start">
            <span class="text-h5">Editar {{ implementoUpdate.placa }}</span>
            <span class="text-caption">{{ implementoUpdate.public_id }}</span>
          </div>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialogUpdateItem = false">
            Fechar
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <EditarImplemento
              @addedImplemento="addedImplemento"
              v-model="implementoUpdate"
              @close="dialogUpdateItem = false"
              v-if="dialogUpdateItem"
            />
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { BasicPermissions, Sequences } from '@/utils/permissions'
import CadastroImplemento from './CadastroImplemento.vue'
import EditarImplemento from './EditarImplemento.vue'
import { date2br } from '@/mixins/convertion'
export default {
  components: {
    CadastroImplemento,
    EditarImplemento,
  },
  data() {
    return {
      dialog: false,
      search: '',
      loading: false,
      headers: [
        {
          text: 'Placa',
          value: 'placa',
        },
        {
          text: 'public_id',
          sortable: false,
          value: 'public_id',
        },
        {
          text: 'Documentos',
          sortable: false,
          value: 'documentos',
        },
        { text: 'Chassis', value: 'chassis' },
        { text: 'Renavam', value: 'renavam' },
        { text: 'Dt fabricação', value: 'manufacturing_date' },
        {
          text: 'Modalidade',
          sortable: false,
          value: 'modalidade.descricao',
        },
        {
          text: 'UF',
          sortable: false,
          value: 'estado.nome',
        },
        {
          text: 'Tara',
          sortable: false,
          value: 'tara',
        },
        {
          text: 'Proprietario',
          value: 'proprietario.business_name',
          gate: true,
        },
        {
          text: 'Ações',
          value: 'actions',
          sortable: false,
        },
      ],
      dialogDeleteItem: false,
      dialogUpdateItem: false,
      implementoDelete: {},
      implementoUpdate: {},
      options: {},
    }
  },
  mixins: [date2br],
  computed: {
    ...mapState('implemento', ['implementos', 'implementos_total']),
    ...mapState('auth', ['empresaAtual']),
    headersComputed() {
      return this.headers.filter((item) => !item.gate || this.empresaAtual.gate)
    },
    permission() {
      return Sequences.Implementos.toString()
    },

    canList() {
      return 1 || this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return 1 || this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return 1 || this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return 1 || this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },

  async created() {
    if (this.canList) {
      await this.getImplementos()
    }
  },

  methods: {
    ...mapActions('implemento', [
      'getImplementos',
      'retrieveImplemento',
      'deleteImplemento',
    ]),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),

    adicionarImplemento() {
      this.$emit('showForm')
    },
    addedImplemento() {
      this.close()
    },
    async handleGetImplementos(params = {}) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      let ordering
      if (sortBy && sortBy.length === 1 && sortDesc && sortDesc.length === 1) {
        ordering = this.options.sortBy[0]
        ordering = this.options.sortDesc[0] ? '-' + ordering : ordering
        ordering = ordering.replaceAll('.', '__')
      }
      if (this.search.length >= 3) {
        params['pesquisa'] = this.search
      }
      this.loading = true
      await this.getImplementos({
        ...params,
        page_size: itemsPerPage,
        page,
        ordering,
      })
      this.loading = false
    },
    async handleSearchImplementos(search) {
      this.loading = true
      if (search.length >= 3) {
        await this.handleGetImplementos({ pesquisa: search })
      } else {
        alert('Digite pelo menos 3 letras da placa do cavalo e aperte TAB!')
      }
      this.loading = false
    },

    async editarImplemento(item) {
      const implemento = await this.retrieveImplemento({ implemento: item })
      this.dialogUpdateItem = true
      this.implementoUpdate = implemento
    },

    prepareDeleteItem(item) {
      this.dialogDeleteItem = true
      this.implementoDelete = item
    },

    async deleteItem(item) {
      if (item.public_id) {
        await this.deleteImplemento(item)
      } else {
        alert('Houve um problema. Tente novamente!')
        return false
      }
      this.dialogDeleteItem = false
      this.implementoDelete = {}
      // const index = this.desserts.indexOf(item)
      // confirm('Are you sure you want to delete this item?') &&
      //   this.desserts.splice(index, 1)
    },

    close() {
      this.dialog = false
    },
  },
}
</script>
