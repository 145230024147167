<template>
    <v-container fluid>
        <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
        <v-row>
            <v-col cols="12" sm="12">
                <BaseCard heading="Implementos">
                    <ListarImplemento />
                </BaseCard>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
// import { mapActions } from 'vuex'
import ListarImplemento from '@/components/patio/veiculos/Implemento/ListarImplemento'

export default {
    components: {
        ListarImplemento,
    },
    data() {
        return {
            page: {
                title: 'Gerenciamento de Implemento',
            },
            breadcrumbs: [
                {
                    text: 'Pátios',
                    disabled: false,
                    to: '/patio/patio',
                },
                {
                    text: 'Implementos',
                    disabled: false,
                },
            ],
        }
    },
    methods: {

    },
}
</script>
  