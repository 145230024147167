<template>
  <div>
    <validation-observer ref="observer" v-slot="{ invalid }">
      <v-card-text class="py-6">
        <v-container>
          <form-wizard color="#00aa9e" :title="null" :subtitle="null" @on-complete="save">
            <tab-content title="Dados do implemento" icon="ti-user">
              <etapa-dados-implemento ref="etapaDadosImplemento" v-model="implemento" />
            </tab-content>

            <tab-content title="Anexar documentos" icon="ti-check">
              <etapa-documentos ref="etapaDocumentos" v-model="documentos" @setNames="setDocs"
                @remove-documento="removeDocumento" />
            </tab-content>
            <v-btn color="black" dark slot="prev">
              <i class="mdi mdi-chevron-left mr-1"></i>
              Anterior
            </v-btn>
            <v-btn color="info" slot="next">
              Próximo
              <i class="mdi mdi-chevron-right ml-1"></i>
            </v-btn>
            <v-btn color="success" slot="finish" :disabled="invalid">
              Finalizar
              <i class="mdi mdi-bookmark-check ml-1"></i>
            </v-btn>
          </form-wizard>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="black darken-1" text @click="close">Cancelar</v-btn>
      </v-card-actions>
    </validation-observer>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'

import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import EtapaDadosImplemento from './etapas/EtapaDadosImplemento'
import EtapaDocumentos from './etapas/EtapaDocumentos'

export default {
  name: 'CadastroImplemento',
  data() {
    return {
      implemento: {},
      documentos: [],
    }
  },
  components: {
    EtapaDadosImplemento,
    EtapaDocumentos,
    FormWizard,
    TabContent,
  },

  computed: {
    ...mapState('auth', ['empresaAtual']),
  },

  methods: {
    ...mapActions('implemento', [
      // 'getImplementos',
      'addImplemento', 'updateDocumentosImplemento'
    ]),
    ...mapActions('template', ['successMessage', 'errorMessage']),
    close() {
      this.$emit('close')
    },
    validate() {
      this.$refs.observer.validate()
      const isValid = !this.$refs.observer.flags.invalid
      return isValid
    },
    wordUpper(event) {
      event.target.value = event.target.value.toUpperCase()
    }, wordUpperChange(field, value) {
      this.value[field] = value.toUpperCase()
    },
    setDocs() { },
    removeDocumento(doc) {
      const index = this.documentosNome.indexOf(doc.attachment_key)
      if (index >= 0) {
        delete this.documentosNome[index]
        this.documentosNome = this.documentosNome.filter(
          (documento) => documento !== null
        )
      }
    },
    async save() {

      if (this.validate()) {
        try {
          const documentosAdicionados = await this.$refs.etapaDocumentos.sendDocs()
          this.implemento.documentos = [...documentosAdicionados]
          //   let proprietario = this.empresaAtual.public_id
          //   if (
          //     this.implemento.proprietario !== null &&
          //     this.implemento.proprietario !== '' &&
          //     this.implemento.proprietario !== undefined
          //   ){
          //     proprietario = this.implemento.proprietario
          //   }
          //   const implemento = await this.editImplemento({
          //     ...this.implemento,
          //     proprietario: proprietario,
          //   })
          //   this.$emit('addedImplemento', implemento)
          //   this.successMessage('Implemento atualizado com sucesso!')
          const implemento = await this.addImplemento({
            ...this.implemento,
          })
          this.$emit('addedImplemento', implemento)
          this.successMessage('Implemento adicionado com sucesso!')
        } catch (error) {
          if (error.response) {
            this.errorMessage(error.response.data)
            return
          }
          if (error.message) {
            this.errorMessage(error.message)
            return
          }
          this.errorMessage(error)
        }
      }
    },
  },
}
</script>
<style></style>
  